import jump from "jump.js";
import "./css/main.scss";

const infoLink = document.querySelector('a[href="#info"]');
const workLinks = document.querySelectorAll('a[href="#work"]');
const connectLink = document.querySelector('a[href="#connect"]');

infoLink.onclick = () => {
  jump("#info");
};

workLinks.forEach(
  link =>
    (link.onclick = () => {
      jump("#work");
    })
);

connectLink.onclick = () => {
  jump("#connect");
};
